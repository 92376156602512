import * as React from 'react'
import { Box, Heading, Text, VStack } from '@chakra-ui/react'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <Box height="calc(100vh - 160px)">
        <VStack justify="center" align="center" h="full">
          <Heading>404: Not Found</Heading>
          <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
        </VStack>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
